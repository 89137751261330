// This probably doesn't need to be it's own package. It doesn't do anything anymore.
// maybe if this had some fallback stuff where it could help with rendering parcels but as of right now I don't see the point.
//
// All this is a map with getters/setters
const slots = {
  'global-nav-secondary': '#global-nav-secondary',
  'global-nav-rail': '#global-nav-rail',
  'page-content': '#page-content',
  'page-header': '#page-header',
  'page-sidebar': '#page-sidebar',
  'page-siderail': '#page-siderail',
  'page-toolbar': '#page-toolbar',
  'secondary-nav': '#secondary-nav',
}

export function getNamedNode(name) {
  const selector = slots[name]
  if (!selector) {
    return undefined
  } else {
    return document.body.querySelector(selector) || undefined
  }
}

export function waitForDomNode(name, timeout = 5000) {
  let stop = () => null

  const domNode = getNamedNode(name)

  if (domNode) {
    const alreadyTherePromise = Promise.resolve(domNode)
    alreadyTherePromise.stop = stop

    return alreadyTherePromise
  }

  const waitForNodePromise = new Promise((resolve, reject) => {
    const observer = new MutationObserver(() => {
      const domNode = getNamedNode(name)
      if (domNode) {
        resolve(domNode)
        waitForNodePromise.stop()
      }
    })

    const timeoutInstance = setTimeout(() => {
      observer.disconnect()
      reject('Timed out waiting for dom node')
    }, timeout)

    stop = () => {
      observer.disconnect()
      clearTimeout(timeoutInstance)
    }

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  })

  waitForNodePromise.stop = stop

  return waitForNodePromise
}

// // expect a mapping of names to dom querySelectors
// export function createSlots(config = {}) {
//   Object.entries(config).forEach(([key, value]) => {
//     // TODO a better way to handle this than just throwing an error
//     if (slots[key]) {
//       const error = generateError(`Slot by name of ${key} already exists`)
//       throw error
//     }
//     slots[key] = value
//   })
// }

export function generateError(message) {
  const errorPrefix = `@wf-mfe/slots`
  return new Error(`${errorPrefix}: ${message}`)
}
